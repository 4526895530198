
import './WinnerCard.css'

const WinnerCard = ({ language, name, province_en, province_fr, src }) => {

  return (
    <li className='winner__card'>
      <p className='name'>{name}</p>
        {language === 'en' &&
            <p className='province'>{province_en}</p>
        }
        {language === 'fr' &&
          <p className='province'>{province_fr}</p>
        }
    </li>
  )

}

export default WinnerCard
