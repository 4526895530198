
import './ComingSoon.css'

const ComingSoon = ({ language }) => {

  return (
    <main id='coming-soon' className='coming-soon'>

      <p>
        Coming Soon
      </p>

    </main>
  )

}

export default ComingSoon
