import browserUtils from '../utils/browserUtils'

const config = {

  lang: '',

  getEnvConfig() {
    const domain = browserUtils.getDomain()
    if (typeof domain !== 'string') {
      console.log(`Invalid domain: ${domain}`)
      return false
    }
    switch (domain) {
      // production
      case 'laboursedormezvous.com':
        this.lang = 'fr'
        return this.env.production
      case 'sleepcountrymattresship.ca':
        this.lang = 'en'
        return this.env.production

      // stage
      case 'mattresship-fr.pubtor.com':
        this.lang = 'fr'
        return this.env.stage
      case 'mattresship.pubtor.com':
        this.lang = 'en'
        return this.env.stage

      // dev
      default:
        // note: defaults to English unless "?lang=fr" is in the querystring
        const querystring = new URLSearchParams(window.location.search)
        this.lang = (querystring.get('lang') === 'fr') ? 'fr' : 'en'
        return this.env.development
    }
  },

  getLang() {
    return this.lang
  },

  env: {

    production: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '950199756',
        domain_en: 'https://sleepcountrymattresship.ca',
        domain_fr: 'https://laboursedormezvous.com'
      }
    },

    stage: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '950199756',
        domain_en: 'https://mattresship.pubtor.com',
        domain_fr: 'https://mattresship-fr.pubtor.com'
      }
    },

    development: {
      frontend: {
        disableConsole: false,
        comingSoon: false,
        offline: false,
        bypassHash: '950199756',
        domain_en: 'http://localhost:3000/?lang=en',
        domain_fr: 'http://localhost:3000/?lang=fr'
      }
    }

  },

  url_mappings: {
    en: {
      '/': '/fr/',
      '/en': '/fr/',
      '/en/': '/fr/',
      '/en/how-to-enter': '/fr/how-to-enter',
      '/en/winners': '/fr/winners',
      '/en/terms': '/fr/terms',
    },
    fr: {
      '/': '/en/',
      '/fr': '/en/',
      '/fr/': '/en/',
      '/fr/how-to-enter': '/en/how-to-enter',
      '/fr/winners': '/en/winners',
      '/en/terms': '/en/terms',
    }
  },

  winners: [
    { name: "Nikita F.", province_en:"Ontario", province_fr:"L'Ontario" , url: "" },
    { name: "Caitlyn G.", province_en:"Nova Scotia", province_fr:"La Nouvelle-Écosse" , url: "" },
    { name: "Jordyn-Lee N.", province_en:"Saskatchewan", province_fr:"La Saskatchewan" , url: "" },
    { name: "Kira B.", province_en:"Ontario", province_fr:"L'Ontario" , url: "" }

   
  ]

}

export default config
