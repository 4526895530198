import { Link, useNavigate } from 'react-router-dom'
import './MainNav.css'
import {NavLink} from 'react-router-dom'

import logoSrc from '../assets/images/logo-header.jpg'
import logoFrSrc from '../assets/images/fr-logo.svg'

const MainNav = ({ isNavExpanded, onNavStateChange, language, onLanguageChange, envConfig }) => {

  let navigate = useNavigate();

  const navClassName = isNavExpanded ? 'expanded' : ''

  const updateNavState = () => {
    onNavStateChange()
  }

  const hideMobileNav = () => {
    const body = document.querySelector('body')
    if (typeof body !== 'object' || body === null) return
    if (body.classList.contains('mobile-nav-expanded')) {
      updateNavState()
    }
  }

  const changeLanguage = (lang) => {
    // method 1: change language by domain
    if (!changeLanguageByDomain(lang)) {
      // method 2: change language with React Routing
      changeLanguageByRoute(lang)
    }
  }

  const changeLanguageByDomain = (lang) => {
    const domain = envConfig.frontend[`domain_${lang}`]
    if (typeof domain === 'string' && domain.trim() !== '') {
      window.location = domain
      console.log('change language by domain')
      return true
    }
    console.log('no domain value')
    return false
  }

  const changeLanguageByRoute = (lang) => {
    switch (lang) {
      case 'fr':
        const newUrlFrench = window.location.pathname.replace('/en', '/fr')
        navigate(newUrlFrench)
        onLanguageChange('fr')
        hideMobileNav()
        break
      default:
        const newUrlEnglish = window.location.pathname.replace('/fr', '/en')
        navigate(newUrlEnglish)
        onLanguageChange('en')
        hideMobileNav()
        break;
    }
  }


  return (
    <div className='main__nav'>
      <div className='main__nav__inner'>

        {language === 'en' &&
          <Link to='/en/'><img className='logo' src={logoSrc} alt='Sleep Country logo – directs to homepage' /></Link>
        }

        {language === 'fr' &&
          <Link to='/fr/'><img className='logo' src={logoFrSrc} alt='Logo Dormez-vous – direction vers la page d’accueil ' /></Link>
        }

        <nav>

          <button 
            className='hamburger'
            onClick={() => {
              updateNavState()
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='white'
            >
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                clipRule='evenodd'
              />
            </svg>
          </button>

          {language === 'en' &&
            <ul className={navClassName + ' nav-en'}>
              <li><NavLink onClick={hideMobileNav} to='/en/'>About Mattresship</NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/en/how-to-enter'>How to Apply</NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/en/winners'>Winners</NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/en/terms'>Terms<span className='show-desktop'> & Conditions</span></NavLink></li>
            </ul>
          }

          {language === 'fr' &&
            <ul className={navClassName + ' nav-fr'}>
              <li><NavLink onClick={hideMobileNav} to='/fr/'>À propos de la bourse <span className='show-desktop'>Dormez-Vous</span></NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/fr/comment-faire'>Comment faire <span className='show-desktop'>une demande</span></NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/fr/gagnants'>Gagnants</NavLink></li>
              <li><NavLink onClick={hideMobileNav} to='/fr/modalites'>Modalités</NavLink></li>
            </ul>
          }

        </nav>

      </div>
    </div>
  )

}

export default MainNav
