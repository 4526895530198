
import './Footer.css'

import tikTokSrc from '../assets/images/TIK-TOK-ICON.svg'

const Footer = ({ language }) => {

  return (
    <footer className='footer beige-bg fw800' add-role='contentinfo' aria-labelledby='footer'>
    
      <div className='footer-container'>
      {language === 'en' && 
      <a className='footer-tikTok' href='https://www.tiktok.com/@sleepcountrycanada' target='_blank'>
        <img src={tikTokSrc} className='footer-tikTok' alt='TikTok logo'/>
      </a>
      }
      {language === 'fr' && 
      <a className='footer-tikTok' href='https://www.tiktok.com/@dormezvousqc' target='_blank'>
        <img src={tikTokSrc} className='footer-tikTok' alt='Logo TikTok'/>
      </a>
      }
      {language === 'en' &&
      <p>
        Post a video on TikTok telling us why you<br className='show-mobile'/> need a new mattress for your chance to win a<br className='show-mobile'/> Mattresship. <br className='show-desktop'/>
        Tag it with <br className='show-mobile' />@SleepCountryCan and both <br className='show-mobile' />#SleepCountryCanada and #Mattresship
      </p>
      }
      {language === 'fr' &&
      <p>
      Publie une vidéo TikTok en nous expliquant pourquoi tu as besoin d'un nouveau matelas, et cours la chance de<br className='show-desktop'/> gagner une bourse Dormez-vous.
        <br className='show-desktop'/>
      Identifie-la avec @dormezvousqc et avec ces deux mots-clics : #DormezVous et #LaBourseDormezVous.
      </p>



      
      }
        {/* <Link to='/terms'>Terms</Link> | <Link to='/privacy'>Privacy</Link> */}
      </div>
      <div className='footer-copyright dark-beige-bg'>
      {language === 'en' &&
        <p className='text-center'>© 2022 Sleep Country Canada</p>
      }
      {language === 'fr' &&
        <p className='text-center'>© 2022 Dormez-vous Canada</p>
      }
      </div>
    </footer>
  )

}

export default Footer
