import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Instructions from '../components/Instructions'

import './HowToApply.css'

const HowToApply = ({ language }) => {

  // routing
  let navigate = useNavigate();

  useEffect(() => {

    console.log('language', language, 'window.location.pathname', window.location.pathname)
    if (window.location.pathname === '/') {
      console.log('redirect and include language')
      navigate(`/${language}`)
    }

  }, [navigate])

  return (
    <main className='main howToApply'>

      <div className='main__inner howToApply__inner'>

       <Instructions language={language}/>

      </div>

    </main>
  )

}

export default HowToApply
