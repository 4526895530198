import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Hero from '../components/Hero'
import Intro from '../components/Intro'
import './About.css'

const About = ({ language }) => {

  // routing
  let navigate = useNavigate();

  useEffect(() => {

    console.log('language', language, 'window.location.pathname', window.location.pathname)
    if (window.location.pathname === '/') {
      console.log('redirect and include language')
      navigate(`/${language}/`)
    }

  }, [navigate])

  return (
    <>      
      <Hero language={language}/>

      <main className='main about'>

        <div className='main__inner about__inner'>

          <Intro language={language} />

        </div>

        <div className=''>  
        
        </div>

      </main>
    </>
  )

}

export default About
