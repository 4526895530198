import {Helmet} from "react-helmet";
import redLeafSrc from '../assets/images/red-leaf.svg'
import './Terms.css'

const Terms = ({ language }) => {

  return (
    <main className='main terms'>
      {language === 'en' &&
      <div className='main__inner terms__inner'>
      <Helmet>
        <meta name="description" content="Read the Terms and Conditions for the Sleep Country Mattresship program." />
      </Helmet>
      <h1 className='sr-only'></h1>
          <div className='terms-intro'>
                <img className='red-leaf text-center' src={redLeafSrc}  alt='An illustrated red maple leaf'/>
                <h1 className='sr-only'>Terms & Conditions</h1>
                <h2 className='intro-header'>Terms<span className='show-desktop'> & Conditions</span></h2>
          </div>
          

        <h5>No purchase necessary. Void where prohibited.</h5>
        <p className='italics'>This promotion is in no way sponsored, endorsed, administered by or associated with Facebook, Instagram, or TikTok.</p>
        <ol>
          <li><p><strong>Sponsor:</strong> Sleep Country Canada Inc., 7920 Airport Road, Brampton, Ontario L6T 4N8.</p></li>
          <li><p><strong>Eligibility:</strong> Open only to legal residents of Canada (excluding Quebec), who:</p>
            <ul>
              <li>are enrolled in a post-secondary learning institution (universities, colleges or trade schools) as of the start of the Promotion Period;</li>
              <li>have a valid, TikTok account. <strong className='italics'>TikTok accounts set to private are not eligible.</strong> If you don’t have a TikTok account, you can get one for free at <a href='https://www.tiktok.com' target='_blank' rel='noopener noreferrer'>www.tiktok.com</a>.</li>
            </ul>
            <p>Employees (and their immediate families and those living in their households) of Sponsor and affiliated companies, franchisees, advertising and promotion agencies are not eligible. Void where prohibited.</p>
          </li>
          <li><p><strong>Timing:</strong> The promotion begins at 09:00 a.m. Eastern Time (“ET”) on September 21, 2022 and ends at 11:59 p.m. ET on October 5, 2022 (“Promotion Period”). Sponsor’s computer is the official timekeeper for all deadlines, and all entries must actually be received by Sponsor during the Promotion Period to be valid.</p> </li>
          <li>
            <p><strong>Prizes:</strong> There are 15 prizes to be awarded to Sleep Country entrants that are legal residents of Canada (excluding Quebec). Each prize consisting of one Bloom Cloud Mattress.</p>
            <p>Total approximate retail value of all prizes available to be won is $15,000.</p>
            <p>All taxes are the winner’s responsibility. No substitution, cash redemption or transfer of prizes allowed except with Sponsor’s permission (which may be granted or withheld for any reason) or where required by law. If potential winners cannot accept prize, the prize may be forfeited in its entirety and awarded to an alternate winner.</p>
          </li>
          <li>
          <p><strong>How to Enter:</strong></p>
            <ul>
              <li>During the Promotion Period, Sponsor will post once on each social media platform (Facebook, Instagram and TikTok) with an invitation to fans to post a video (a “Post”). To enter the promotion, you must:
                <ol className='roman'>
                  <li>Post a video indicating why you need a new mattress;</li>
                  <li>Include the hashtag #SleepCountryCanada and #Mattresship; and</li>
                  <li>Tag @SleepCountryCan.</li>
                </ol>
                Collectively, this is your “entry”.
              </li>

            </ul>
             <p>Limit one entry per person, regardless of the method of entry. Multiple participants are not permitted to share the same social media account or device.</p>
          </li>
          <li><p><strong>Winner Selection:</strong> On or about October 7, 2022, a group of 5 Sleep Country employees will evaluate and award the potential winners based on the entries received during the Promotion Period. Potential winners will be notified by direct message on TikTok (depending on entry method). Potential winners must comply with all terms and conditions of these official rules, and winning is contingent upon fulfilling all requirements and requests of Sponsor during the verification process. Before being declared a winner, the selected entrant must correctly answer, unaided, a time limited mathematical skill testing question. The potential winners may be required to execute and return to Sponsor a notarized affidavit of eligibility, a liability release, and, except where prohibited by law, a publicity release (collectively the “Affidavit/Release”) in the form provided by Sponsor in order to claim the prize. The Affidavit/Release must be returned to Sponsor by the date and time indicated. If a potential winner cannot be contacted within seven days of the first contact attempt, if the potential winner fails to return the Affidavit/Release within the specified time, if any prize or prize notification is returned as undeliverable, if the potential winner is found to be ineligible, or if the potential winner does not comply with the official rules or the requests of Sponsor, then Sponsor may disqualify the potential winner and randomly select an alternate from among all remaining eligible entries. If more than two alternate potential winners are disqualified, Sponsor may choose to cancel the Promotion as related to that prize and not award that prize.</p></li>
          <li><p><strong>Publicity:</strong> By accepting a prize, winners agree, where lawful, that Sponsor and its affiliates may use winner’s name, likeness, biographical information, voice and statements attributed to winner (if truthful) for advertising and promotional purposes without additional consideration or approval.</p></li>
          <li>
            <p><strong>Entries:</strong> Proof of sending the entry does not constitute proof that the entry was received. Sponsor is not responsible for lost, late, damaged, garbled, corrupted, misdirected, illegible, incomplete, or stolen entries. Any attempts by any entrant to enter more than the allowed number of times by using multiple or different social media accounts, email addresses, identities or other methods will void those entrant's entries.</p>
            <p>Normal time, toll, connection and usage rates, if any, charged by your internet service provider will apply. If there is a dispute about an entry, the authorized account holder is deemed the entrant.</p>
            <p>Automated entries (including but not limited to entries submitted using any bot, script, macro, or sweepstakes service), third party entries, copies, bots and/or mechanical reproductions are not permitted and may be disqualified. </p>
          </li>
          <li>
            <p><strong>Entry Materials:</strong> By submitting an entry, entrant provides Sponsor with a royalty-free, irrevocable, perpetual, non-exclusive license to use, reproduce, modify, publish, create derivative works from, and display such entry and video materials in whole or in part specifically including without limitation the entrant’s name, likeness and biographical information, on a worldwide basis, and to incorporate it into other works, in any form, media or technology now known or later developed, including for promotional or marketing purposes. If requested, entrant will sign any documentation necessary for Sponsor or its designees to make use of the rights entrant is granting to use the entry and Post materials.</p>
            <p>Entrants are responsible for securing necessary consents, waivers, talent and location releases, and licenses for any visual and audio material contained in an entry. If any minors are pictured in the materials, the entrant must have permission from the minor’s guardian and be able to provide evidence of the permission upon request. Upon request, the entrant must be able to provide releases from all persons (other than the designated entrant) featured in the entry.  Each entry must be an original work of the entrant, and entrant must possess sufficient rights in all elements of the work prior to entry. All eligible entries will be made available to the public.</p>
            <p>To be eligible, the entry must not:</p>
            <ul>
                <li>violate or infringe another’s rights, including but not limited to privacy, publicity, copyright or intellectual property rights; </li>
                <li>disparage Sponsor or any other person or party affiliated with the promotion and administration of this promotion; </li>
                <li>be inappropriate, indecent, lewd, obscene, hateful, tortious, defamatory, slanderous or libelous; </li>
                <li>promote bigotry, racism, hatred or harm against any group or individual, or discrimination based on race, gender, religion, nationality, disability, sexual orientation or age;</li>
                <li>contain the last name, phone number, address, or email address of any person, including the entrant;</li>
                <li>contain or otherwise make available any unsolicited or unauthorized advertising, commercial pitch, promotional materials, junk mail, spam, pyramid schemes, or any other form of solicitation;</li>
                <li>contain material that is unlawful, in violation of or contrary to the laws or regulations in any jurisdiction where submission is created; or</li>
                <li>contain any viruses, spyware, malware, or other malicious components.</li>
            </ul>
            <p>Sponsor reserves the right to disqualify and delete any entries that do not comply with these official rules or is inconsistent with Sponsor’s brand identity, but Sponsor has no duty to review the entries prior to posting them to the website.</p>
          </li>
          <li>
            <p><strong>Release:</strong> By accepting a prize, winners: (1) release and discharge TikTok, Sponsor and its parent company, affiliates, advertising and promotion agencies and all of their respective employees, officers, directors, representatives and agents (the “released parties”) from all liability or damage due in whole or part to the acceptance, possession, use or misuse of a prize, or participation in this promotion; and (2) waive any “moral rights” in the entry.</p>
            <p>The released parties are not responsible for: (1) any incorrect or inaccurate information, whether caused by entrants, Sponsor, printing errors or by any of the equipment or programming associated with or utilized in the promotion; (2) technical failures of any kind, including, but not limited to malfunctions, interruptions or disconnections in phone lines or network hardware or software; (3) unauthorized human intervention in any part of the entry process or the promotion; (4) technical or human error which may occur in the administration of the promotion or the processing of entries; (5) any injury or damage to persons or property which may be caused, directly or indirectly, in whole or in part, from entrant’s participation in the promotion or receipt or use of any prize or while traveling to or from any prize-related activity; or (6) liability in connection with the promotion. If for any reason an entry is confirmed to have been erroneously deleted, lost or otherwise destroyed or corrupted, entrant’s sole remedy is another entry in the promotion during the Promotion Period.</p>
          </li>
          <li><p><strong>Fraud/Technical Failure:</strong> Sponsor reserves the right to cancel or modify the promotion if fraud, technical failures or any other factor beyond Sponsor’s reasonable control impairs the integrity of the promotion, as determined by Sponsor. In such event, Sponsor reserves the right to determine winners from among the eligible entries received up to the time of the impairment. Sponsor reserves the right to disqualify any individual tampering with the entry process or the operation of the promotion or to be acting in violation of these official rules or any other promotion, or in an unsportsmanlike, improper or disruptive manner. An attempt to deliberately damage any website or platform or app or undermine the legitimate operation of a promotion is a violation of criminal and civil law, and, should such an attempt be made, Sponsor reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor’s failure to enforce any term of these official rules is not a waiver of that provision.</p></li>
          <li><p><strong>Agreement to Official Rules:</strong> By participating in this promotion, entrant agrees to these official rules and the decisions of the Sponsor.</p></li>
          <li><p><strong>Disputes:</strong> Except where prohibited, entrant agrees that: (1) all disputes, claims, and causes of action arising out of or connected with this promotion or any prize awarded will be resolved individually, without resort to any form of class action, and exclusively by the courts of the Province of Ontario; (2) all claims, judgments, and awards are limited to actual out-of-pocket costs incurred, including costs associated with entering this promotion, but not attorneys’ fees; and (3) under no circumstances will entrant be permitted to obtain awards for, and entrant waives all rights to claim, indirect, punitive, incidental and consequential damages and any other damages, other than for actual out-of-pocket expenses, and any and all rights to have damages multiplied or otherwise increased. All issues and questions concerning the construction, validity, interpretation and enforceability of these official rules, or the rights and obligations of the entrant and Sponsor in connection with this promotion, will be governed by, and construed in accordance with, the laws of the Province of Ontario, without giving effect to any choice of law or conflict of law rules, and by participating, entrant agrees to personal jurisdiction in Ontario.</p></li>
          <li><p><strong>Privacy:</strong> Information collected from entrants is subject to the Sponsor’s privacy policy at <a href='https://www.sleepcountry.ca/en/privacy' target='_blank' rel='noopener noreferrer'>www.sleepcountry.ca/en/privacy</a></p></li>
          <li><p><strong>Odds of Winning:</strong> Odds of winning will be determined by the number of eligible entries received.</p></li>

        </ol>

      </div>
      
      }

      {language === 'fr' &&
      
      <div className='main__inner terms__inner'>
      <Helmet>
        <meta name="description" content="Lire les modalités du programme de la bourse Dormez-vous." />
      </Helmet>
      <h1 className='sr-only'></h1>
      <div className='terms-intro'>
                <img className='red-leaf text-center' src={redLeafSrc}  alt='Une feuille d’érable illustrée'/>
                <h1 className='intro-header'>Modalités</h1>
          </div>
        <h5>Aucun achat requis. Nul là où la loi l’interdit.</h5>
        <p className='italics'>Cette promotion n’est en aucun cas commanditée, endossée ou administrée par Facebook, Instagram ou TikTok, ou associée à ceux-ci.</p>
        <ol>
          <li><p><strong>Commanditaire :</strong> Dormez-vous inc., 59 boulevard Hymus, Pointe-Claire (Québec) H9R 1E2.</p></li>
          <li><p><strong>Admissibilité :</strong>  Le Concours est ouvert aux résidents autorisés du Québec (excluant le reste du Canada) qui :</p>
            <ul>
              <li>sont inscrits dans un établissement d’enseignement postsecondaire (au cégep, à l’université ou dans une école de commerce) au début de la Période du concours; </li>
              <li>possèdent un compte TikTok valide. <strong className='italics'>Les comptes TikTok privés ne sont pas admissibles.</strong> Si vous n’avez pas de compte TikTok, vous pouvez vous en créer un gratuitement à l’adresse <a href='https://www.tiktok.com' target='_blank' rel='noopener noreferrer'>www.tiktok.com</a>.</li>
            </ul>
            <p>Les employés (ainsi que ceux qui habitent avec un employé ou font partie de sa famille immédiate) du Commanditaire, de ses filiales, de ses sociétés mères, de ses licenciés et de ses agences de publicité et de promotion affiliées ne sont pas admissibles au Concours. Nul là où la loi l’interdit.</p>
          </li>
          <li><p><strong>Période :</strong> Le Concours débute à 9 h, heure normale de l’Est (HNE), le 21 septembre 2022 et se termine à 23 h 59 (HNE) le 5 octobre 2022 (la « Période du Concours »). L’heure indiquée sur l’ordinateur du Commanditaire est le chronométreur officiel de toutes les échéances, et toutes les inscriptions devront être officiellement reçues par le Commanditaire pendant la Période du concours pour être valables.</p> </li>
          <li>
            <p><strong>Prix :</strong> Il y a cinq (5) prix à attribuer aux résidents autorisés du Québec. Le prix consiste en un matelas Bloom Cloud.</p>
            <p>La valeur totale approximative de tous les prix à gagner est de 5 000 $.</p>
            <p>Toutes les taxes sont à la charge des gagnants. Aucune substitution, aucune compensation ni aucun transfert de prix ne seront accordés, sauf si le Commanditaire en donne l’autorisation (qui peut être accordée ou retirée à sa discrétion et pour quelque raison que ce soit) ou si la loi l’exige. Dans le cas où un gagnant ne peut pas accepter son prix, celui-ci peut lui être retiré et attribué à un autre gagnant.</p>
          </li>
          <li>
          <p><strong>Mode de participation :</strong></p>
            <ul>
              <li>Pendant la Période du concours, le Commanditaire publiera une fois sur chaque plateforme de médias sociaux (Facebook, Instagram et TikTok) en invitant les abonnés à publier une vidéo TikTok (une « Publication »). Pour participer au Concours, vous devez : 
                <ol className='roman'>
                  <li>publier une vidéo qui souligne pourquoi vous avez besoin d’un nouveau matelas;</li>
                  <li>inclure les mots-clics #DormezVous et #LaBourseDormezVous; et</li>
                  <li>identifier @dormezvousqc.</li>
                </ol>
                Ces actions constituent votre « Inscription ».
              </li>

            </ul>
             <p>Limite d’une Inscription par personne, quel que soit le mode de participation. Une même adresse courriel ou un même appareil ne peuvent être partagés par plusieurs participants.</p>
          </li>
          <li><p><strong>Sélection des gagnants  :</strong> Le 7 octobre 2022 ou aux alentours, un groupe composé de 5 employés de Dormez-vous évalueront les inscriptions reçues pendant la Période du concours. Les gagnants potentiels seront contactés par message privé sur la plateforme choisie. Les gagnants potentiels doivent se conformer à toutes les modalités de ce présent Règlement, et ne pourront être déclarés gagnants que s’ils se soumettent à toutes les exigences et demandes du Commanditaire pendant la procédure de vérification. Avant d’être déclarés gagnants, les participants sélectionnés devront répondre correctement et sans aide à une question d’aptitude mathématique dans un délai prescrit. Les gagnants potentiels peuvent être tenus de signer et de renvoyer un affidavit notarié, une décharge de responsabilité et, sauf si la loi l’interdit, une décharge de publicité (collectivement, l’« Affidavit/la Décharge ») sous la forme fournie par le Commanditaire afin de réclamer le prix. Si un gagnant potentiel ne peut être contacté dans un délai de sept jours suivant la première tentative de contact, si le gagnant potentiel ne renvoie pas l’Affidavit/la Décharge dans le délai prescrit, si la remise d’un prix ou la notification de remise d’un prix sont retournées comme étant non distribuables, ou si le gagnant potentiel ne remplit pas les conditions mentionnées au présent Règlement ou les demandes du Commanditaire, le Commanditaire pourra, à sa discrétion, disqualifier le gagnant potentiel et procéder à la sélection d’un autre gagnant parmi les autres participants admissibles. Si plus de deux autres gagnants potentiels sont disqualifiés, le Commanditaire pourra, à sa discrétion, décider d’annuler la promotion liée au Concours et de ne pas attribuer le prix.</p></li>
          <li><p><strong>Publicité :</strong> En acceptant un prix, les gagnants acceptent, là où la loi le permet, que le Commanditaire et ses filiales utilisent le nom, l’apparence, les renseignements biographiques, la voix et les messages attribués aux gagnants (si véridiques) à des fins publicitaires et promotionnelles sans leur accord ou approbation additionnels.</p></li>
          <li>
            <p><strong>Inscription :</strong> La preuve d’envoi de l’Inscription ne constitue pas une preuve de sa réception. Le Commanditaire n’est pas responsable des inscriptions perdues, en retard, endommagées, déformées, corrompues, mal adressées, illisibles, incomplètes ou volées. Toutes les Publications deviennent la propriété du Commanditaire et ne feront l’objet d’aucun accusé de réception ni d’aucun renvoi. Toute tentative d’un participant de s’inscrire plus de fois que le nombre autorisé en utilisant plusieurs ou différentes adresses courriels, identités ou autres méthodes entraînera l’annulation de l’Inscription de ce participant.</p>
            <p>Les tarifs liés au temps d’utilisation et aux frais de service, de connexion et d’utilisation d’Internet facturés par votre fournisseur de services Internet s’appliqueront. En cas de litige concernant une Publication, le titulaire du compte est considéré comme étant le participant.</p>
            <p>Les inscriptions automatisées (incluant, sans s’y limiter, les inscriptions soumises à l’aide d’un robot, d’un script, d’un macro ou d’une loterie), les inscriptions par des tiers, les copies, les fac-similés et/ou reproductions mécaniques ne sont pas permis et seront rejetés.</p>
          </li>
          <li>
            <p><strong>Matériel d’inscription :</strong> En soumettant une Publication, les participants accordent au Commanditaire une licence gratuite, irrévocable, perpétuelle et non exclusive lui permettant de reproduire, de modifier, de publier, de créer du contenu dérivé et d’afficher le matériel vidéo et la Publication en entier ou en partie, incluant, sans s’y limiter, le nom, l’apparence et les renseignements biographiques du participant, et ce, dans le monde entier, et de les incorporer dans d’autres contenus, sous quelque forme médiatique ou technologique actuelle ou à venir, y compris à des fins promotionnelles ou de marketing. Sur demande, les participants signeront tout document nécessaire pour que le Commanditaire ou ses représentants puissent utiliser le matériel d’inscription en toute liberté de droits.</p>
            <p>Les participants sont responsables de l’obtention des consentements, des renonciations, des décharges pour les personnes et les lieux, et des licences nécessaires pour tout contenu visuel et audio inclus dans les Publications. Si des mineurs figurent dans les Publications, le participant doit avoir l’autorisation du tuteur du mineur et être en mesure de fournir la preuve de cette autorisation sur demande. Les participants doivent être en mesure de fournir les décharges de toutes les personnes (autres que le participant lui-même) figurant dans les Publications sur demande. Toutes les Publications doivent constituer une œuvre originale de la part des participants, et les participants doivent posséder tous les droits de propriété adéquats liés à tous les éléments présents dans les Publications avant de soumettre leur Inscription. Toutes les publications admissibles seront rendues publiques.</p>
            <p>Pour être admissibles, les Publications ne doivent pas:</p>
            <ul>
                <li>violer ou enfreindre les droits d’autrui, incluant, sans s’y limiter, les droits à la vie privée et à la vie publique, et les droits d’auteur et de propriété intellectuelle;</li>
                <li>dénigrer le Commanditaire ou toute autre personne ou organisation affiliée au Concours et à sa mise en œuvre; </li>
                <li>être inappropriées, indécentes, offensantes, obscènes, haineuses, de nature illégale, diffamatoires ou injurieuses; </li>
                <li>promouvoir le sectarisme, le racisme, la haine ou le préjudice à l’encontre de tout groupe ou individu, ou promouvoir la discrimination fondée sur la race, le sexe, la religion, la nationalité, le handicap, l’orientation sexuelle ou l’âge;</li>
                <li>contenir le nom de famille, le numéro de téléphone, l’adresse postale ou l’adresse courriel de toute personne, y compris le participant;</li>
                <li>contenir ou fournir de toute autre manière de la publicité, des argumentaires commerciaux, du matériel promotionnel, du courrier indésirable, des pourriels, de la fraude pyramidale ou toute autre forme de sollicitation;  </li>
                <li>contenir du matériel illégal, en violation des lois ou règlements de toute juridiction où le matériel d’inscription a été créé ou contraire à ceux-ci; ou </li>
                <li>contenir des virus informatiques, des logiciels espions, des logiciels malveillants ou d’autres contenus nuisibles. </li>
            </ul>
            <p>Le Commanditaire se réserve le droit de disqualifier ou de supprimer toute Inscription qui ne se conforme pas au présent Règlement ou dont le contenu va à l’encontre de l’identité de la marque, mais n’est pas tenu d’examiner les inscriptions avant de les publier sur son site web.  </p>
          </li>
          <li>
            <p><strong>Exonération de responsabilité :</strong> En acceptant un prix, les gagnants : (1) libèrent TikTok, le Commanditaire, sa société mère, ses sociétés affiliées, agences promotionnelles et de publicités et tous leurs employés, responsables, directeurs, représentants et agents respectifs (collectivement, les « Renonciataires ») de toute responsabilité ou de tout dommage dû, en entier ou en partie, à l’acceptation, la possession, l’utilisation légitime ou abusive d’un prix, ou à la participation au Concours; et (2) renoncent aux « droits moraux » relatifs à l’Inscription.</p>
            <p>Les Renonciataires ne sont pas responsables : (1) de toute information incorrecte ou inexacte, qu’elle provienne des participants, du Commanditaire, de l’impression ou de tout équipement ou logiciel associé au Concours; (2) des défaillances techniques de quelque type, incluant, mais sans s’y limiter, le mauvais fonctionnement, les interruptions ou les déconnexions des lignes téléphoniques ou du matériel ou des logiciels des réseaux informatiques; (3) de l’intervention de personnes non autorisées dans toute partie du processus de l’inscription ou du Concours; (4) des erreurs techniques ou humaines qui peuvent survenir pendant le déroulement du Concours ou le traitement des inscriptions; (5) de toute blessure ou dommage à des personnes ou à des biens pouvant être causés, directement, indirectement, en entier ou en partie, par la participation du participant au Concours ou par la réception ou l’utilisation d’un prix ou lors d’un déplacement vers ou depuis toute activité liée au prix; et (6) de toute responsabilité en lien avec le Concours. Si, pour quelque raison que ce soit, il est confirmé qu’une participation a été supprimée, perdue, ou autrement détruite ou corrompue par erreur, le seul recours du participant est de soumettre son inscription à nouveau pendant la Période du concours.</p>
          </li>
          <li><p><strong>Problèmes techniques et falsification :</strong> Le Commanditaire se réserve le droit, à sa discrétion, d’annuler ou de modifier le Concours si des pratiques frauduleuses, des défaillances techniques ou tout autre facteur échappant à son administration appropriée sont susceptibles d’entraver l’exploitation légitime du Concours. Dans ce cas, le Commanditaire se réserve le droit de sélectionner les gagnants parmi les inscriptions admissibles reçues jusqu’au moment de la défaillance. Le Commanditaire se réserve le droit de disqualifier toute personne qui altère le processus d’inscription ou le déroulement du Concours, ou qui agit en violation du présent Règlement ou de tout autre concours, ou de manière déloyale, inappropriée ou perturbatrice. Toute tentative d’endommager un quelconque site web de façon délibérée ou d’entraver l’exploitation légitime d’un concours constitue une violation des lois criminelles et civiles et, si une telle tentative a lieu, le Commanditaire se réserve le droit d’engager le recours en dommages et intérêts jusqu’aux limites permises par la loi. Le fait que le Commanditaire n’applique pas une disposition du présent Règlement ne constitue en aucun cas une renonciation à cette disposition. </p></li>
          <li><p><strong>Acceptation du règlement :</strong> En participant à ce Concours, les participants acceptent de se conformer au présent Règlement et aux décisions du Commanditaire (et de ses tiers autorisés). </p></li>
          <li><p><strong>Différends :</strong> Sauf là où la loi l’interdit, les participants acceptent que : (1) tous les litiges, réclamations et causes d’action découlant du Concours ou de tout prix attribué ou étant liés à ceux-ci seront résolus de manière individuelle, sans recourir à aucune forme de recours collectif, et exclusivement par les tribunaux de la province de Québec; (2) toutes les réclamations, tous les jugements et toutes les décisions des tribunaux sont limités aux frais réels encourus, incluant ceux liés à la participation au Concours, mais pas aux honoraires d’avocats; et (3) en aucun cas, les participants ne seront autorisés à obtenir des compensations, et que le participant renonce à tout droit de réclamer des dommages indirects, punitifs, accessoires et consécutifs ou tout autre dommage, autre que les dépenses réelles, et tout droit de voir les dommages multipliés ou autrement augmentés. Tous problèmes et toutes questions concernant l’élaboration, la validité, l’interprétation et l’applicabilité du présent Règlement, ou les droits et obligations des participants et du Commanditaire en lien avec le Concours, seront régis par les lois de la province de Québec, sans donner effet à un choix de loi ou à des règles de conflits de lois, et qu’en s’inscrivant, les participants acceptent la compétence personnelle du Québec.</p></li>
          <li><p><strong>Confidentialité :</strong> Les renseignements recueillis auprès des participants sont soumis à la politique de confidentialité du Commanditaire telle qu’énoncée au <a href='https://www.dormezvous.com/fr/privacy' target='_blank' rel='noopener noreferrer'>www.dormezvous.com/fr/privacy</a>.</p></li>
          <li><p><strong>Chances de gagner :</strong> Les chances de gagner seront déterminées par le nombre d’inscriptions admissibles reçues.</p></li>

        </ol>

      </div>
      
      
      }

    </main>
  )

}

export default Terms
