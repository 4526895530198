
import './Offline.css'

const Offline = ({ language }) => {

  return (
    <main id='offline' className='offline'>
      <p>
        Website offline for scheduled updates. Please check <span className='nowrap'>again soon.</span>
      </p>
    </main>
  )

}

export default Offline
