import { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'


// utils
import browserUtils from './utils/browserUtils'
import config from './config/index'
import securityUtils from './utils/securityUtils'

// pages: intercepts
import ComingSoon from './pages/ComingSoon'
import Offline from './pages/Offline'

// pages: live site
import About from './pages/About'
import HowToApply from './pages/HowToApply'
import Winners from './pages/Winners'
import Terms from './pages/Terms'

// common page components
import MainNav from './components/MainNav'
import Footer from './components/Footer'

// generate a new bypass hash
// const word = ''
// const hash = securityUtils.getHash(word).toString()
// console.log('hash', hash)

// config
const envConfig = config.getEnvConfig()

// setup dependencies
const querystring = new URLSearchParams(window.location.search)
const querystringBypass = querystring.get('bypass')
const querystringBypassHash = querystringBypass !== null ? securityUtils.getHash(querystring.get('bypass')).toString() : ''

function getShowOffline() {
  if (envConfig.frontend.offline && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function getShowComingSoon() {
  if (envConfig.frontend.comingSoon && querystringBypassHash !== envConfig.frontend.bypassHash) return true
  return false
}

function App() {

  /*
  -----------------------------------------------------------------
  querystring to route redirects
  -----------------------------------------------------------------
  */

  // const qsLang = browserUtils.getLanguageFromQuerystring('lang')
  // const qsPage = querystring.get('page')
  // if (qsLang !== null && qsPage !== null) {
  //   switch (qsPage) {
  //     case '':
  //     case 'about':
  //       console.log('redirect to /')
  //       window.location = `/${qsLang}`
  //       break;
  //     case 'how-to-enter':
  //     case 'terms':
  //     case 'winners':
  //       console.log('redirect to', qsPage)
  //       window.location = `/${qsLang}/${qsPage}`
  //       break;
  //     default:
  //     // do nothing
  //   }
  // }

  // useRef
  const showOffline = useRef(getShowOffline())
  const showComingSoon = useRef(getShowComingSoon())

  // useState
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const [language, setLanguage] = useState(() => {
    return config.getLang()
  })

  // runtime setup
  if (envConfig.frontend.disableConsole) browserUtils.disableConsole()

  /*
  -----------------------------------------------------------------
  DOM manipulation
  -----------------------------------------------------------------
  */

  useEffect(() => {

    // set page title
    switch (language) {
      case 'fr':
        document.title = 'La bourse dormez vous';
        document.documentElement.setAttribute("lang", 'fr');
        break
      default:
        document.title = 'Sleep Country Mattresship'
        break
    }

    // toggle overflow lock
    const body = document.querySelector('body')
    if (typeof body === 'object' && body !== null) {
      if (isNavExpanded) {
        if (!body.classList.contains('mobile-nav-expanded')) body.classList.add('mobile-nav-expanded')
      } else {
        if (body.classList.contains('mobile-nav-expanded')) body.classList.remove('mobile-nav-expanded')
      }
    }

  }, [isNavExpanded, language])

  /*
  -----------------------------------------------------------------
  callbacks
  -----------------------------------------------------------------
  */

  const updateNavState = () => {
    setIsNavExpanded(!isNavExpanded)
  }

  const updateLanguage = (value) => {
    switch (value) {
      case 'fr':
        setLanguage('fr')
        break;
      default:
        if (language !== value) setLanguage('en')
        break;
    }
  }

  /*
  -----------------------------------------------------------------
  views
  -----------------------------------------------------------------
  */

  // show: offline (eg, maintenance mode)
  if (showOffline.current) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<Offline />} />
          </Routes>
        </Router>
      </>
    )
  }

  // show: coming soon
  if (showComingSoon.current) {
    return (
      <>
        <Router>
          <Routes>
            <Route path='*' element={<ComingSoon />} />
          </Routes>
        </Router>
      </>
    )
  }

  // live UX
  return (
    <>
  
      <Router>

        <MainNav 
          isNavExpanded={isNavExpanded} 
          onNavStateChange={updateNavState} 
          language={language} 
          onLanguageChange={updateLanguage} 
          envConfig={envConfig} 
        />

        <Routes>

          <Route path='/fr/modalites' element={<Terms language={language} />} />
          <Route path='/fr/gagnants' element={<Winners language={language} winners={config.winners} />} />
          <Route path='/fr/comment-faire' element={<HowToApply language={language} />} />
        
          <Route path='/en/terms' element={<Terms language={language} />} />
          <Route path='/en/winners' element={<Winners language={language} winners={config.winners} />} />
          <Route path='/en/how-to-enter' element={<HowToApply language={language} />} />

          <Route path='/*' element={<About language={language} />} />

          {/* <Route path='/' element={<About language={language} />} /> */}

        </Routes>

      </Router>

      <Footer language={language} />
    </>
  )

}

export default App
