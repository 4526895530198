import NoWinners from '../components/NoWinners'
import WinnerCard from '../components/WinnerCard'
import {Helmet} from "react-helmet";

import redLeafSrc from '../assets/images/red-leaf.svg'

// TODO: return layout for placeholder when there is no winners yet
import './Winners.css'

const Winners = ({ language, winners }) => {

  const noWinners = () => {
    console.log('no winners found')
    return 

  }

  const getWinners = () => {

    if (!Array.isArray(winners)) return noWinners()

    const list = (winners.slice(0,10)).map((winner, i) => {
      return <WinnerCard key={i} language={language} name={winner.name} province_en={winner.province_en} province_fr={winner.province_fr} src={winner.url} />
    })
    
    return (
      <ul>
        {list}
      </ul>
    )

  }

  return (
    <main className='main winners'>

      <div className='main__inner winners__inner'>

      {language === 'en' &&
        <div className='winners-header'>
              <Helmet>
                  <meta name="description" content="This year’s Sleep Country Mattresship winners." />
              </Helmet>
              <img className='red-leaf' src={redLeafSrc} alt='An illustrated red maple leaf' />
              <h1>Winners</h1>
              <p className='winners-subheader'>Congratulations to the winners of the 2022 Sleep Country Mattresship!</p>
        </div>
      }
      {language === 'fr' &&
        <div className='winners-header'>
              <Helmet>
                  <meta name="description" content="Les gagnants de la bourse Dormez-vous de cette année." />
              </Helmet>
              <img className='red-leaf' src={redLeafSrc} alt='Une feuille d’érable illustrée' />
              <h1>Gagnants</h1>
              <p className='winners-subheader'>Félicitations aux gagnants de la bourse Dormez-vous 2022!</p>
          </div>
      }


        {getWinners()}

        {/* <NoWinners language={language}/> */}
      </div>

    </main>
  )

}

export default Winners
