import {Helmet} from "react-helmet";
import './Instructions.css'
import redLeafSrc from '../assets/images/red-leaf.svg'
import phoneMattress from '../assets/images/phone-mattress.png'
import phoneTarget from '../assets/images/phone-target.png'
import phoneHash from '../assets/images/phone-hash.png'

const Instructions =({language}) => {   

return (
    <div className='instructions-main'>
        {language === 'en' &&
            <div className='instructions-container'>
            <Helmet>
                <meta name="description" content="Instructions to enter Sleep Country Mattresship for a chance to receive a new Bloom Cloud mattress." />
            </Helmet>
            <div className='instructions-intro'>
                <img className='red-leaf' src={redLeafSrc}  alt='An illustrated red maple leaf'/>
                <h1 className='intro-header'>How to Apply</h1>
                <p>Tell us why you need a new mattress with a custom TikTok video and post it by October 5<sup>th</sup>, 2022.  </p>
            </div>
            <div className='instructions-trio'>
                <div className='instructions-bubble round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon icon-mattress' src={phoneMattress} alt='An icon of a mobile phone featuring a mattress illustration'/>
                    </div>
                    <h3 className='red-text text-left'>Tell us why you need a new one</h3>
                    <p className='instruction-text text-left fw600'><span className='nowrap'>Is your mattress sagging?</span><br/><span className='nowrap'>Or too thin? Let us know why your</span><br/><span className='nowrap'>mattress must go.</span></p>
                </div>
                <div className='instructions-bubble round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon' src={phoneTarget} alt='An icon of a mobile phone featuring a bulls-eye symbol illustration'/>
                    </div>
                    <h3 className='red-text text-left'>Or, tell us your goals</h3>
                    <p className='instruction-text text-left fw600'><span className='nowrap'>Why do you want to study hard and</span><br/><span className='nowrap'>do well in school?</span></p>
                </div>
                <div className='instructions-bubble round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon' src={phoneHash} alt='An icon of a mobile phone featuring an illustrated chat box with a hashtag symbol'/>
                    </div>
                    <h3 className='red-text text-left'>Don't forget to tag us</h3>
                    <p className='instruction-text text-left fw600'><span className='nowrap'>@SleepCountryCan</span><br/><span className='nowrap'>#SleepCountryCanada</span><br/><span className='nowrap'>#Mattresship</span></p>
                </div>
            </div>
            <p className='text-center mt20 mb20'>Be sure your TikTok account is not set to private so we can view your video submission.</p>
            <a className='text-center mt20 mb20 tok-cta show-mobile' href='https://www.tiktok.com/' target='_blank' rel='noopener noreferrer noreferrer'>Open TikTok</a>
            </div>

        }
        {language === 'fr' &&
        <div className='instructions-container'>
            <Helmet>
                <meta name="description" content="Instructions pour s’inscrire à la bourse Dormez-vous et courir la chance d’obtenir un nouveau matelas Bloom Cloud." />
            </Helmet>
            <h1 className='sr-only'>Règles</h1>
            <div className='instructions-intro'>
                <img className='red-leaf' src={redLeafSrc}  alt='Une feuille d’érable illustrée'/>
                <h2 className='intro-header'>Comment faire une demande</h2>
                <p className='intro-description fr'>Dis-nous pourquoi tu as besoin d’un nouveau matelas à l’aide d’une vidéo personnalisée TikTok, puis publie-la avant le 5 octobre 2022.</p>
            </div>
            <div className='instructions-trio'>
                <div className='instructions-bubble fr round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon icon-mattress' src={phoneMattress} alt="Icône d'un téléphone mobile avec une illustration de matelas"/>
                    </div>
                    <h3 className='navy-text text-left'><span className='nowrap'>Dis-nous pourquoi tu as besoin</span><br/><span className='nowrap'>d’un nouveau matelas</span></h3>
                    <p className='instruction-text fr text-left fw600'>
                    <span className='nowrap'>Ton matelas est-il trop mou?</span>
                    <br/><span className='nowrap'>Ou trop mince? Dis-nous pourquoi ton</span>
                    <br/><span className='nowrap'>matelas doit être remplacé</span> 
                    <br/><span className='nowrap'>par un nouveau.</span></p>
                </div>
                <div className='instructions-bubble fr round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon' src={phoneTarget} alt=" Icône d'un téléphone portable avec une illustration du symbole d’une cible"/>
                    </div>
                    <h3 className='navy-text text-left'><span className='nowrap'>Ou encore, parle-nous de</span><br/><span className='nowrap'>tes objectifs</span></h3>
                    <p className='instruction-text fr text-left fw600'><span className='nowrap'>Pourquoi veux-tu étudier et</span><br/><span className='nowrap'>réussir à l'école?</span></p>
                </div>
                <div className='instructions-bubble fr round border-grey'>
                    <div className='instructions-icon-container'>
                        <img className='instructions-icon' src={phoneHash} alt=" Icône d'un téléphone portable avec une illustration d’une boîte de clavardage et un symbole de mot-clic"/>
                    </div>
                    <h3 className='navy-text text-left'><span className='nowrap'>Et surtout, n’oublie de nous</span><br/><span className='nowrap'>identifier:</span></h3>
                    <p className='instruction-text fr text-left fw600'>
                    <span className='nowrap'>@dormezvousqc</span>
                        <br/><span className='nowrap'>#DormezVous</span>
                        <br/><span className='nowrap'>#LaBourseDormezVous</span>
                    </p>
                </div>
            </div>
            <p className='text-center mt20 mb20'>Assure-toi que ton compte TikTok n’est pas privé pour qu’on puisse voir ta vidéo.</p>
            <a className='text-center mt20 mb20 tok-cta show-mobile' href='https://www.tiktok.com/' target='_blank' rel='noopener noreferrer noreferrer'>Ouvrir TikTok</a>
            </div>
        }

    </div>
)




}

export default Instructions