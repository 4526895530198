import {Helmet} from "react-helmet";
import './Hero.css'

import heroDesktop from '../assets/images/hero-banner.jpg'
import heroMobile from '../assets/images/hero-mobile.jpg' 
import heroDesktopFr from '../assets/images/hero-banner-fr.jpg'
import heroMobileFr from '../assets/images/hero-mobile-fr.jpg'

const Hero = ({ language }) => {

  return (
    <header className='hero'>
    
        {language === 'en' &&
          <div className='hero__container'>
            <Helmet>
              <meta name="description" content="Sleep Country Mattresship awards post-secondary students of any age, location, income or grade with a new mattress—better sleep can mean better grades." />
            </Helmet>
          
              <img className='header__hero__desktop hide-mobile show-tablet show-desktop' alt='Sleep Country Mattresship logo SLEEP COUNTRY MATTRESSHIP. EST 2022.' src={heroDesktop} />
              <img className='header__hero__mobile show-mobile hide-tablet hide-desktop' alt='Sleep Country Mattresship logo SLEEP COUNTRY MATTRESSHIP. EST 2022.' src={heroMobile} />
          </div>
        }
        {language === 'fr' &&
          <div className='hero__container'>
            <Helmet>
              <meta name="description" content="La bourse Dormez-vous offre un nouveau matelas aux étudiants de niveau postsecondaire de tout âge, lieu de résidence, revenu ou niveau scolaire." />
            </Helmet>
            <img className='header__hero__desktop hide-mobile show-tablet show-desktop' alt='Logo de la bourse Dormez-vous : BOURSE DORMEZ-VOUS. DEPUIS 2022.' src={heroDesktopFr} />
            <img className='header__hero__mobile show-mobile hide-tablet hide-desktop' alt='Logo de la bourse Dormez-vous : BOURSE DORMEZ-VOUS. DEPUIS 2022.' src={heroMobileFr } />
          </div>
        }
    </header>
  )

}

export default Hero
