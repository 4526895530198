import './Intro.css'
import studentSrc from '../assets/images/student.jpg'
import redLeafSrc from '../assets/images/red-leaf.svg'
const Intro = ({ language }) => {

  return (
    <div className='intro'>

      <div className='intro__description text-center'>

        {language === 'en' &&
          <div>
          <h1 className='sr-only'>Sleep Country Mattresship Est. 2022</h1>
            <img className='red-leaf' src={redLeafSrc}  alt='An illustrated red maple leaf'/>
            <h2 className='intro-header'>What is a Sleep Country Mattresship?</h2>


            <p className='intro-intro show-desktop'>Sleep Country Mattresship is the first-ever scholarship that provides post-secondary students with a new, super-comfortable <a href="https://www.sleepcountry.ca/bloom-cloud-mattress/product/92162M" target="_blank" class="nowrap blue-link">Bloom Cloud mattress</a>. Because whether you want to be a healthcare hero, a top-shelf tradesperson or the next mogul of the metaverse, your dream career starts with getting your best night's sleep. With better sleep comes more focus, improved memory, and increased creativity. In short, better grades.</p>
            <p className='intro-intro show-mobile'></p>

            <p className='intro-intro'>Applying is easy. Just post a TikTok video by October 5<sup>th</sup>, 2022 telling us why you need a new mattress. Tag it with @SleepCountryCan and both #SleepCountryCanada and #Mattresship.</p>
              <p className='intro-intro'> Be sure your TikTok account is not set to private so we can view your video submission.</p>
            <div className='round copy-img-half border-grey'>
              <img className='image-one-third' src={studentSrc} alt='A young woman wearing headphones lies on a mattress while working on her laptop' />
              <div className='copy-two-thirds rules-list'>
                <h3 className='red-text text-left'>Who can apply for a Mattresship?</h3>
                <p className='text-left'>Any students enrolled in a post-secondary program within Canada.<br/>There is:</p>
                <ul>
                  <li>no age requirement </li>
                  <li>no grade requirement</li>
                  <li>no location restrictions in Canada</li>
                </ul>
              </div>

            </div>
          </div>
        }

        {language === 'fr' &&
          <div>
          <h1 className='sr-only'>La bourse Dormez-vous Depuis 2022</h1>
            <img className='red-leaf' src={redLeafSrc}  alt='Une feuille d’érable illustrée'/>
            <h2 className='intro-header'>Qu’est-ce que la bourse Dormez-vous?</h2>

            <p className='intro-intro fr show-desktop'>La bourse Dormez-vous est la toute première bourse d'études qui offre un nouveau <a href="https://www.sleepcountry.ca/fr/matelas-bloom-cloud/product/92162M" target="_blank" class="nowrap blue-link">matelas Bloom Cloud</a> super confortable à des étudiants de niveau postsecondaire. Que tu souhaites devenir un héros de la santé, un entrepreneur de haut niveau ou le prochain magnat de la finance, la carrière de tes rêves commence par une bonne nuit de sommeil. Un meilleur sommeil permet de mieux se concentrer, d'améliorer sa mémoire et d'accroître sa créativité. En bref, d’avoir de meilleures notes.</p>
            <p className='intro-intro fr show-mobile'>La bourse Dormez-vous est la toute première bourse d'études qui offre un nouveau <a href="https://www.sleepcountry.ca/fr/matelas-bloom-cloud/product/92162M" target="_blank" class="nowrap blue-link">matelas Bloom Cloud</a> super confortable à des étudiants de niveau postsecondaire. Car un meilleur sommeil permet de mieux se concentrer, d'améliorer sa mémoire et d'accroître sa créativité. En bref, d’avoir de meilleures notes.</p>


            <p className='intro-intro fr'>Faire une demande, c’est super facile. Il te suffit de publier une vidéo TikTok avant le 5 octobre 2022 en nous disant pourquoi tu as besoin d'un nouveau matelas. Identifie ta publication avec @dormezvousqc et avec ces deux mots-clics : #DormezVous et #LaBourseDormezVous.</p>

            <p className='intro-intro fr'>Assure-toi que ton compte TikTok n’est pas privé pour qu’on puisse voir ta vidéo.</p>



            <div className='round copy-img-half border-grey'>
              <img className='image-one-third' src={studentSrc} alt='Une jeune femme portant des écouteurs est allongée sur un matelas et travaille sur son ordinateur portable.' />
              <div className='copy-two-thirds rules-list'>
                <h3 className='navy-text text-left'>Qui peut faire une demande pour <br/>la bourse Dormez-vous?</h3>
                <p className='text-left'>Tout étudiant inscrit à un programme d'études <br/>postsecondaires au Canada.<br/>Il n'y a :</p>

                <ul>
                  <li>aucune condition d'âge</li>
                  <li>aucune exigence de niveau scolaire</li>
                  <li>aucune restriction de lieu au Canada</li>
                </ul>
              </div>
              </div>

            </div>
        }

      </div>

    </div>
  )

}

export default Intro
